import { UseQueryOptions } from "react-query";
import { API_INVEST_NOW_MODAL_URL } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";
import { IBroker, ILink } from "src/types/common";

export interface IInvestResponse {
    data: {
        preferredBroker: IBroker | null;
        availableBrokers: IBroker[];
        containsLeverageProducts: boolean;
        settingsLink: ILink;
        depotLink: ILink;
        firstLink: ILink;
        secondLink: ILink;
        savingsPlan?: ISavingsPlanData;
    };
    dict: {
        buttonBroker: string;
        buttonCopyIsin: string;
        derivativesWarning: string;
        notYourPreferredBroker: string;
        investDescription: string;
        placeholderBroker: string;
        headerBroker: string;
        headerInvestNow: string;
        headerIsin: string;
        headerDepot: string;
        subHeaderBroker: string;
        subHeaderInvestNow: string;
        subHeaderIsin: string;
        subHeaderDepot: string;
        questions: string;
        savingsPlan?: ISavingsPlanDict;
    };
}

export interface ISavingsPlanData {
    preferredBroker: IBroker | null;
    brokers: IBroker[];
    settingsLink: ILink;
}

export interface ISavingsPlanDict {
    header: string;
    subHeader: string;
    placeholder: string;
    button: string;
    notYourPreferredBroker: string;
}

interface IRequest {
    isin: string;
    wikifolioId: string;
    wikifolioSecurityId?: string;
}

export function useInvestQuery({ isin, wikifolioId, wikifolioSecurityId }: IRequest, options: UseQueryOptions<IInvestResponse>) {
    const queryParams = new URLSearchParams({
        isin,
        wikifolioId,
    });
    if (wikifolioSecurityId) {
        queryParams.set("wikifolioSecurityId", wikifolioSecurityId);
    }

    return useQuery<IInvestResponse>(API_INVEST_NOW_MODAL_URL, {
        queryParams,
        ...options,
    });
}
