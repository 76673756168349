import { useEffect, useState } from "react";
import {
    Box,
    Flex,
    Image,
    ImageProps,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Stack,
    StackDivider,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useCustomActivityMutation } from "src/api/client/custom-activity.api";
import { EnumActivityType, useGenericActivityMutation } from "src/api/client/generic-activity.api";
import { IInvestResponse, useInvestQuery } from "src/api/client/invest.api";
import { ArrowLink } from "src/components/base/arrow-link";
import { Button } from "src/components/base/button";
import { ComboBox, ComboBoxItem } from "src/components/base/combo-box";
import { WfLink, WfLinkUnstyled } from "src/components/base/wf-link";
import { WfModalCloseButton } from "src/components/base/wf-modal-close-button";
import { CopyIsinButton } from "src/components/common/buttons/copy-isin-button/copy-isin-button";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { IconInfo } from "src/components/icons";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { IBroker } from "src/types/common";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";
import { buildUrl } from "src/utils/responsive-image-util";

const DERIVATIVES_WARNING_TEST_ID = "derivatives-warning";

// if there are more than 6 brokers, the dropdown should be scrollable
export const MAX_HEIGHT_BROKER_LIST = "270px";

interface IBrokerImageProps extends ImageProps {
    broker: IBroker;
}
export const BrokerImage = ({
    broker: {
        image: { url, alt },
    },
    ...imageProps
}: IBrokerImageProps) => <Image src={buildUrl(url)} alt={alt ?? undefined} h={4} w="auto" {...imageProps} />;

export interface IInvestNowModalProps extends Omit<ModalProps, "children"> {
    isin: string;
    wikifolioId: string;
    wikifolioSecurityId?: string;
    onRequestSuccess?: () => void;
    onRequestSettled?: () => void;
}

export const InvestNowModal = (props: IInvestNowModalProps) => {
    const { t } = useTranslation("common");
    const { isin, wikifolioId, wikifolioSecurityId, onRequestSuccess, onRequestSettled, ...modalProps } = props;
    const toast = useWfToast();
    const {
        data: responseData,
        isLoading,
        isError,
    } = useInvestQuery(
        {
            isin,
            wikifolioId,
            wikifolioSecurityId,
        },
        {
            enabled: modalProps.isOpen,
            onError: () => {
                toast({ status: EnumToastStatus.Error, title: t("general-error") });
                modalProps.onClose();
            },
            onSuccess: onRequestSuccess,
            onSettled: onRequestSettled,
        }
    );

    // Dev-Note: Move the loading of the data outside of the invest-now-modal to invest-button
    useEffect(() => {
        if (modalProps.isOpen && !isLoading && responseData) {
            onRequestSettled?.();
        }
    }, [modalProps.isOpen, isLoading, responseData, onRequestSettled]);

    if (!modalProps.isOpen || isLoading || isError || !responseData) {
        return null;
    }

    return <InvestNowModalContent {...props} {...responseData} />;
};

interface IContentProps extends IInvestNowModalProps, IInvestResponse {}

export const InvestNowModalContent = ({
    isin,
    wikifolioId,
    data: {
        containsLeverageProducts,
        preferredBroker,
        availableBrokers,
        savingsPlan,
        settingsLink,
        depotLink,
        firstLink: faqsLink,
        secondLink: contactLink,
    },
    dict,
    ...modalProps
}: IContentProps) => {
    const hasPreferredBroker = preferredBroker !== null;
    const hasPreferredSavingsPlanBroker = Boolean(savingsPlan?.preferredBroker);
    const createCustomActivity = useCustomActivityMutation();
    const genericActivity = useGenericActivityMutation();
    const { t } = useTranslation("common");

    const [selectedBroker, setSelectedBroker] = useState(preferredBroker);
    const [selectedSavingsPlan, setSelectedSavingsPlan] = useState(savingsPlan?.preferredBroker);
    const showSavingsPlan = hasPreferredSavingsPlanBroker || Boolean(savingsPlan && savingsPlan!.brokers?.length > 0);

    const onBrokerLinkClick = () => {
        const brokerName = selectedBroker!.name;
        // TODO: we have a followup ticket to clarify how we want to track in the future (marketo / generic activity)
        createCustomActivity({ activity: "BrokerLinkClicked", value: brokerName });
        genericActivity({ activityTypeId: EnumActivityType.InvestViaBrokerlinkPressed, parameter: `${wikifolioId},${brokerName}` });
        modalProps.onClose();
    };

    const onSavingsPlanNextButtonClick = () => {
        modalProps.onClose();
        genericActivity({
            activityTypeId: EnumActivityType.CreateSavingsPlanViaBrokerLinkClicked,
            parameter: wikifolioId,
        });
    };

    return (
        <Modal {...modalProps}>
            <ModalOverlay />
            <ModalContent maxW={["full", "750px", "750px", "708px"]}>
                <ModalHeader pb={1}>{dict.headerInvestNow}</ModalHeader>
                <WfModalCloseButton />
                <ModalBody>
                    <Text fontSize={["md", "xl"]}>{dict.subHeaderInvestNow}</Text>
                    <VStack my={5} divider={<StackDivider borderColor="gray.100" />} spacing={[3, 4]} align="flex-start">
                        <Stack w="100%" direction={["column", "row"]} divider={<StackDivider borderColor="gray.100" />} spacing={[3, 5]}>
                            <VStack w="100%" spacing={[2, 3]} align="flex-start">
                                <VStack spacing={1} align="flex-start">
                                    <Text fontWeight="bold">{dict.headerIsin}</Text>
                                    <Text fontSize="sm">{dict.subHeaderIsin}</Text>
                                </VStack>
                                <Text fontWeight="bold" fontSize="xl" lineHeight="tall" className="gtm-copy-isin">
                                    {isin}
                                </Text>
                                <CopyIsinButton isin={isin} size={["md", "sm"]} w={["100%", "auto"]}>
                                    {dict.buttonCopyIsin}
                                </CopyIsinButton>
                                {/* Dev-Note: we override the default rich text numeric list here because it should be slightly different in the InvestNow Modal */}
                                <Box sx={{ "> div > ol": { ml: 2, li: { m: 0, mt: 1 }, "li:first-of-type": { mt: 0 } } }}>
                                    <SimpleRichText fontSize="sm" text={dict.investDescription} />
                                </Box>
                            </VStack>
                            <VStack w="100%" spacing={[2, 3]} align="flex-start">
                                <VStack w="100%" spacing={2} align="flex-start">
                                    <VStack spacing={1} align="flex-start">
                                        <Text fontWeight="bold">{dict.headerBroker}</Text>
                                        <Text fontSize="sm">{dict.subHeaderBroker}</Text>
                                    </VStack>
                                    {hasPreferredBroker ? (
                                        <Flex align="center" h={6}>
                                            <BrokerImage broker={preferredBroker!} />
                                        </Flex>
                                    ) : (
                                        <ComboBox
                                            value={selectedBroker}
                                            onChange={value => setSelectedBroker(value)}
                                            placeholder={dict.placeholderBroker}
                                            menuListProps={{ maxH: MAX_HEIGHT_BROKER_LIST }}
                                        >
                                            {availableBrokers.map(broker => (
                                                <ComboBoxItem key={broker.name} value={broker} h={5}>
                                                    <BrokerImage broker={broker} h={3} />
                                                </ComboBoxItem>
                                            ))}
                                        </ComboBox>
                                    )}
                                    <Button
                                        as={WfLinkUnstyled}
                                        href={selectedBroker?.url}
                                        target="_blank"
                                        isDisabled={selectedBroker === null}
                                        size={["md", "sm"]}
                                        w={["100%", "auto"]}
                                        onClick={onBrokerLinkClick}
                                        onAuxClick={triggerCallbackOnMiddleClickEvent(() => onBrokerLinkClick())}
                                        isExternal
                                        className="gtm-invest-modal__broker-button"
                                        data-gtm-isin={isin}
                                    >
                                        {dict.buttonBroker}
                                    </Button>
                                </VStack>
                                {hasPreferredBroker ? (
                                    <VStack spacing={1} align="flex-start">
                                        <Text fontSize="sm">{dict.notYourPreferredBroker}</Text>
                                        {settingsLink && (
                                            <WfLink size="sm" href={settingsLink.url} target={settingsLink.target}>
                                                {settingsLink.name}
                                            </WfLink>
                                        )}
                                    </VStack>
                                ) : (
                                    <VStack spacing={1} align="flex-start">
                                        <Text fontWeight="bold">{dict.headerDepot}</Text>
                                        <Text fontSize="sm">{dict.subHeaderDepot}</Text>
                                        {depotLink && (
                                            <WfLink size="sm" href={depotLink.url} target={depotLink.target}>
                                                {depotLink.name}
                                            </WfLink>
                                        )}
                                    </VStack>
                                )}
                            </VStack>
                        </Stack>
                        {showSavingsPlan && (
                            <Stack w="100%" spacing={[2, 4]} direction={["column", "row"]}>
                                <VStack spacing={1} align="flex-start">
                                    <Text fontWeight="bold">{dict.savingsPlan!.header}</Text>
                                    <Text fontSize="sm">{dict.savingsPlan!.subHeader}</Text>
                                </VStack>
                                <VStack w={["100%", "auto"]} spacing={2} align="flex-start">
                                    {hasPreferredSavingsPlanBroker ? (
                                        <Flex align="center" h={6}>
                                            <BrokerImage broker={savingsPlan!.preferredBroker!} />
                                        </Flex>
                                    ) : (
                                        <ComboBox
                                            value={selectedSavingsPlan!}
                                            onChange={value => setSelectedSavingsPlan(value!)}
                                            placeholder={dict.savingsPlan!.placeholder}
                                            menuListProps={{ maxH: MAX_HEIGHT_BROKER_LIST }}
                                        >
                                            {savingsPlan!.brokers.map(broker => (
                                                <ComboBoxItem key={broker.name} value={broker} h={5}>
                                                    <BrokerImage broker={broker} h={3} />
                                                </ComboBoxItem>
                                            ))}
                                        </ComboBox>
                                    )}
                                    <Button
                                        as={WfLinkUnstyled}
                                        href={selectedSavingsPlan?.url}
                                        target="_blank"
                                        isDisabled={selectedSavingsPlan === null}
                                        size={["md", "sm"]}
                                        w={["100%", "auto"]}
                                        onClick={onSavingsPlanNextButtonClick}
                                        onAuxClick={triggerCallbackOnMiddleClickEvent(() => onSavingsPlanNextButtonClick())}
                                        isExternal
                                        className="gtm-invest-modal__savings-plan-broker-button"
                                        data-gtm-isin={isin}
                                    >
                                        {dict.savingsPlan!.button}
                                    </Button>
                                </VStack>
                            </Stack>
                        )}
                        {containsLeverageProducts && (
                            <VStack spacing={2} align="flex-start" data-test-id={DERIVATIVES_WARNING_TEST_ID}>
                                <Flex align="center" color="orange.600">
                                    <IconInfo transform="rotate(180deg)" boxSize={3} mr={1} fill="orange.100" />
                                    <Text fontWeight="bold">{t("important-notice")}</Text>
                                </Flex>
                                <SimpleRichText fontSize="sm" text={dict.derivativesWarning} />
                            </VStack>
                        )}
                    </VStack>
                </ModalBody>
                <ModalFooter
                    bg="gray.50"
                    py={[4, 4]}
                    px={[3, 5]}
                    alignItems={["flex-start", "center"]}
                    justifyContent="space-between"
                    gridRowGap={2}
                    flexDir={["column", "row"]}
                >
                    <Text as="strong">{dict.questions}</Text>
                    <VStack spacing={2} align={["flex-start", "flex-end"]}>
                        {faqsLink && (
                            <ArrowLink size="sm" href={faqsLink.url} target={faqsLink.target}>
                                {faqsLink.name}
                            </ArrowLink>
                        )}
                        {contactLink && (
                            <ArrowLink size="sm" href={contactLink.url} target={contactLink.target}>
                                {contactLink.name}
                            </ArrowLink>
                        )}
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
